import CircularProgress from '@material-ui/core/CircularProgress';

export default function CircularProgressIndicator() {
    return (
        <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <CircularProgress />
        </div>
    )
}