import React, { useContext } from 'react'
import { Route } from 'react-router-dom'
import CircularProgressIndicator from '../components/CircularProgressIndicator'
import { Context } from '../contexts/cart'

export default function CustomRoute({
    component: Component,
    ...rest
}) {
    const { loading } = useContext(Context)

    return <Route
        {...rest}
        render={(props) => {
            if (loading) {
                return (
                    <CircularProgressIndicator />
                )
            }

            return (
                <Component {...props} />
            )
        }}
    />
}