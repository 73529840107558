import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router } from 'react-router-dom';
import Routes from './routes';
import history from './services/history';
import { SnackbarProvider } from 'notistack';
import { CartProvider } from './contexts/cart';
import { ConfirmationServiceProvider, ConfirmationDrawerServiceProvider } from './services/confirmationService';
import RefreshListener from './components/RefreshListener';

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <SnackbarProvider autoHideDuration={3000} maxSnack={1}>
        <CartProvider>
          <RefreshListener />
          <ConfirmationServiceProvider>
            <ConfirmationDrawerServiceProvider>
              <Router history={history}>
                <Routes />
              </Router>
            </ConfirmationDrawerServiceProvider>
          </ConfirmationServiceProvider>
        </CartProvider>
      </SnackbarProvider>
    </React.Fragment >
  )
}

export default App;
