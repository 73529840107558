import React from 'react';
import Button from '@material-ui/core/Button';
import { Box, Drawer, FormControl, FormLabel, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { currencyFormat } from '../../utils/currencyUtils';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        color: '#454545',
    },
    title: {
        flexGrow: 1,
        color: '#101010',
        fontWeight: 600,
        fontSize: "18px",
        textTransform: "uppercase"
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#fff',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    offset: theme.mixins.toolbar,
}));

export default function ConfirmationDrawer({ address, fullname, cellphone, paymentMethod, open, onSubmit, onClose, cpfOrCnpj, changeFor, orderType }) {
    const classes = useStyles();

    return (
        <Drawer
            open={open}
            anchor="bottom"
            onClose={onClose}
        >
            <Box pt={1}>
            <div style={{
                display: "flex",
                margin: "8px 0 8px 0",
                justifyContent: "center"
            }}>
                <div style={{
                    background: "#DBDBDB",
                    height: "4px",
                    borderRadius: "5px",
                    width: "17%",
                }}></div>
            </div>
                <Toolbar>
                    <Typography className={classes.title} style={{ flex: 2 }}>
                        Confirmar Pedido
                    </Typography>
                    <div>
                        <IconButton
                            edge="end"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="close"
                            onClick={onClose}

                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </Box>
            <FormControl>
                <Box style={{ padding: "0px 16px 16px 16px" }}>
                    <Box pb={1}>
                        {orderType === "delivery" &&
                            <>
                                < FormLabel style={{ fontWeight: "bold", color: "#454545", fontSize: "16px" }}>
                                    Dados da entrega
                                </FormLabel>
                                <Typography style={{ fontSize: "14px", color: "#7e7e7e" }}>
                                    {address}
                                </Typography>
                            </>}
                        <Typography style={{ fontSize: "14px", color: "#7e7e7e" }}>
                            {fullname}
                        </Typography>
                        <Typography style={{ fontSize: "14px", color: "#7e7e7e" }}>
                            {cellphone}
                        </Typography>
                        {cpfOrCnpj &&
                            <Typography style={{ fontSize: "14px", color: "#7e7e7e" }}>
                                CPF/CNPJ: {cpfOrCnpj}
                            </Typography>
                        }
                    </Box>
                    <Box pt={0.5}>
                        <Typography style={{ fontWeight: "bold", color: "#454545", fontSize: "16px" }}>
                            Forma de pagamento
                        </Typography>
                    </Box>
                    <Typography style={{ fontSize: "14px", color: "#7e7e7e", fontWeight: "bold" }}>
                        {paymentMethod}
                    </Typography>
                    {changeFor > 0 &&
                        <Typography style={{ fontSize: "14px", color: "#7e7e7e" }}>
                            Troco para: {currencyFormat(changeFor)}
                        </Typography>
                    }
                    <Box mt={2} style={{
                        justifyContent: "space-between",
                        textAlign: "center"
                    }}>
                        <Button
                            onClick={onSubmit}
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{
                                color: '#fff',
                                borderRadius: '5px',
                                height: "50px",
                                fontWeight: 700,
                                fontSize: "14px"
                            }}
                        >
                            CONFIRMAR PEDIDO
                        </Button>
                        <Box mt={1}>
                            <Button
                                onClick={onClose}
                                fullWidth style={{
                                borderRadius: '5px',
                                height: "50px",
                                fontWeight: 700,
                                fontSize: "14px",
                                color: "#FF611D" }}>
                                Alterar dados
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </FormControl>
        </Drawer >
    );
}
