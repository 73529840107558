import axios from 'axios';
import config from '../config';

const baseURL = config.API_BASE_URL;

const api = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json'
    },
});

export default api;
