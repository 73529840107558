import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CustomRoute from './CustomRoute'
import LazyImport from '../components/LazyImport';

export default function Routes() {
    const Home = LazyImport(() => import('../pages/Home'/* webpackChunkName: 'home' */));
    const SearchProduct = LazyImport(() => import('../pages/SearchProduct'/* webpackChunkName: 'searchProduct' */));
    const Cart = LazyImport(() => import('../pages/Cart'/* webpackChunkName: 'cart' */));
    const AccountBalanceGate = LazyImport(() => import('../pages/AccountBalanceGate'/* webpackChunkName: 'accountBalanceGate' */));
    const AccountBalance = LazyImport(() => import('../pages/AccountBalance'/* webpackChunkName: 'accountBalance' */));
    const Orders = LazyImport(() => import('../pages/Orders'/* webpackChunkName: 'orders' */));
    const OrderDetail = LazyImport(() => import('../pages/OrderDetail'/* webpackChunkName: 'orderDetail' */));
    const OrdersInstructions = LazyImport(() => import('../pages/OrdersInstructions'/* webpackChunkName: 'orderDetail' */));
    const Information = LazyImport(() => import('../pages/Information'/* webpackChunkName: 'information' */));

    return (
        <Switch>
            <CustomRoute path="/menu" component={Home} />
            <CustomRoute path="/search" component={SearchProduct} />
            <CustomRoute path="/cart" component={Cart} />
            <CustomRoute path="/account-balance-gate" component={AccountBalanceGate} />
            <CustomRoute path="/account-balance" component={AccountBalance} />

            <Route
                path="/orders"
                render={({ match: { url } }) => (
                    <>
                        <CustomRoute path={`${url}/`} component={Orders} exact />
                        <CustomRoute path={`${url}/detail/:id`} component={OrderDetail} />
                        <CustomRoute path={`${url}/instructions/:id`} component={OrdersInstructions} />
                    </>
                )}
            />

            <CustomRoute path="/information" component={Information} />
            <Redirect from="/" to="/menu" />
        </Switch>
    )
}
