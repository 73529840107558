import React, { Suspense, lazy } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';

function LazyImport(Component) {
  const ComponentLoadable = lazy(Component)
  return props => (
    <Suspense fallback={
      <div
        style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <CircularProgress />
      </div>
    }>
      <ComponentLoadable {...props} />
    </Suspense >
  );
}

export default LazyImport;