import React, { createContext } from 'react';

import useCart from './hooks/useCart';

const Context = createContext();

function CartProvider({ children }) {
    const {
        loading, addToCart, removeFromCart, clearCart, updateCart, total, itemCount, products, orderType, setOrderType, paymentMethod, setPaymentMethod,
        changeFor, setChangeFor, neighborhood, setNeighborhood, cpfCnpj, setCpfCnpj, restaurant, city, setCity,
        discountCoupon, applyDiscountCoupon, removeDiscountCoupon, discount, discountCode, setDiscountCode
    } = useCart();

    return (
        <Context.Provider value={{
            loading, addToCart, removeFromCart, clearCart, updateCart, total, itemCount, products, orderType, setOrderType, paymentMethod, setPaymentMethod,
            changeFor, setChangeFor, neighborhood, setNeighborhood, cpfCnpj, setCpfCnpj, restaurant, city, setCity,
            discountCoupon, applyDiscountCoupon, removeDiscountCoupon, discount, discountCode, setDiscountCode
        }}>
            {children}
        </Context.Provider>
    );
}

export { Context, CartProvider };