const dev = {
    API_BASE_URL: "http://localhost:8080",
    WS_BROKER_URL: "ws://localhost:8080/live",
};

const prod = {
    API_BASE_URL: "https://api.godino.app",
    WS_BROKER_URL: "wss://api.godino.app/live",
};

const environment = process.env.NODE_ENV;
const config = environment === "development"
    ? dev
    : prod;

export default config;
