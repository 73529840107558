import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogActions, Typography } from '@material-ui/core';

export default function ConfirmationDialog({ title, message, open, onSubmit, onClose, showCancel, showConfirm, showOk }) {
    return (
        <Dialog
            open={open}
        >
            <DialogTitle
                style={{ color: "#101010", fontWeight: "bold", fontSize: "18px" }}
            >
                {title}
            </DialogTitle>
            <DialogContent>
                <Typography style={{textAlign: "justify"}}>
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions>
                {
                    showCancel &&
                    <Button
                        fullWidth
                        style={{
                            height: "50px",
                            backgroundColor: "#EDEDED",
                            borderRadius: "5px",
                            color: "#ADADAD",
                        }}
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                }
                {
                    showConfirm &&
                    <Button
                        fullWidth
                        style={{
                            height: "50px",
                            backgroundColor: "#FF611D",
                            borderRadius: "5px",
                            color: "#fff",
                        }}
                        onClick={onSubmit}
                    >
                        Continuar
                    </Button>
                }
                {
                    showOk &&
                    <Button
                        onClick={onSubmit}
                        style={{ backgroundColor: "#FF611D", color: "#fafafa" }}
                    >
                        Ok
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}
