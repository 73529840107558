import React, { useContext, useEffect } from 'react';
import { Context } from '../../contexts/cart';

const RefreshListener = React.memo(() => {
    const { itemCount } = useContext(Context);

    useEffect(() => {
        const unloadCallback = (event) => {
            if (itemCount > 0) {
                event.preventDefault();
                event.returnValue = "";
                return "";
            }
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, [itemCount]);

    return null;
})

export default RefreshListener;
